
// Default .button class
.button {
    position: relative;
    @extend %button;
    display: inline-block;
    padding: ($block-padding * 2/3) ($block-padding);
    background: $color-secondary;
    border: 1px solid $color-secondary-light;
    color: $color-white;
    outline: none;
    font-size: $font-size-body - 0.4;

    &:active {
        top: 1px;
    }

    &:hover {
        background: $color-secondary-light;
        color: $color-white;
    }
}
    
.read-more {
    @extend .button;
}
