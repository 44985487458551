
// Clear paragraph styles
p {
	margin: 0;
}

// Reset strong tags
strong {
	font-weight: $font-bold;
}

// Reset hr tags 
hr {
	border: $border;
}

blockquote {
	@extend %blockquote;
	font-size: $h3;
	color: $color-darkgrey;
	font-family: $font-family-title;
	font-weight: $font-light;
	margin-bottom: $paragraph-margin;
	line-height: 1.9;
}
