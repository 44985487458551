
//==========  List styles  ==========//

// list item styles
%default-list {
    padding: 0 0 0 $block-padding;
    margin: 0 0 $paragraph-margin;
}

%default-ul {
    list-style: disc;
    @extend %default-list;
}

%default-ol {
    list-style: decimal;
    @extend %default-list;
}

// list item reset
%reset-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


//==========  Buttons and default styles  ==========//

// base button styles
// extend for different button styles
%button {
    border: none;
    border-radius: 30px;
    transition: $transition;
    text-transform: uppercase;
    font-size: $font-size-body - 0.3;
    font-weight: $font-bold;
    letter-spacing: 2px;
}

%hover {
    background: $color-secondary;
}

// extend for default .read-more
%read-more {
    @extend %button;
}

